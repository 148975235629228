import { bemCn } from '@shared/utils/helpers/bem-cn';
import ButtonDownloadApp from '@shared/components/button-download-app/button-download-app';
import Logotype from '@/shared/components/logotype/logotype';
import FooterNav, { type FooterNavProps } from '../footer-nav/footer-nav';
import logoBookmakerRatings from '@assets/logo-bookmaker-ratings.png?url';
import LangSelector from '@/widgets/core/components/lang-selector/lang-selector';
import ButtonIcon from '@shared/components/button-icon/button-icon';
import SocialLinks from '@/widgets/core/components/social-links/social-links';
import i18n from '@/shared/i18n/i18n';
import { useTranslation } from 'react-i18next';
import { AppFeature, COMPANY_NAME, SUPPORT_LINK, LOGO_BOOMAKER_RATINGS, ANDROID_APP } from '@/app-config';
import './page-footer.scss';

const navInfo: FooterNavProps = {
  title: i18n.t('footer-nav.title.Information', 'Информация'),
  items: [
    {
      title: i18n.t('footer.nav.item.Rules', 'Правила'),
      link: '/rules/general-rules',
    },
    {
      title: i18n.t('footer-nav.item.Bonus and actions', 'Бонусы и Акции'),
      link: '/bonuses',
      disabled: !AppFeature.BONUS_AND_PROMO,
    },
    // {
    //   title: i18n.t('footer-nav.item.partner-program', 'Партнерская программа'),
    //   link: '#',
    // },
  ]
};

const navCategory: FooterNavProps = {
  title: i18n.t('footer-nav.title.Categories', 'Категории'),
  items: [
    {
      title: i18n.t('footer-nav.item.Live', 'Live'),
      link: '/live',
      disabled: AppFeature.DISABLE_SPORTBOOK,
    },
    {
      title: i18n.t('footer-nav.item.Line', 'Линия'),
      link: '/line',
      disabled: AppFeature.DISABLE_SPORTBOOK,
    },
    {
      title: i18n.t('main-nav.casino', 'Казино'),
      link: '/games',
    },
    // {
    //   title: 'Результаты',
    //   link: '/results',
    // },
  ]
};

const navSupport: FooterNavProps = {
  title: 'Support and trust',
  items: [
    {
      title: 'Bonuses and Promotions',
      link: '/bonuses',
    },
    {
      title: 'How to play',
      link: '/rules/terms',
    },
    {
      title: 'Terms and conditions',
      link: '/rules/terms',
    },
    {
      title: 'Privacy Policy',
      link: '/rules/privacy-policy',
    },
    {
      title: 'Responsible gaming',
      link: '/rules/terms',
    },
    {
      title: 'Cookies policy',
      link: '/rules/privacy-policy',
    },
  ]
};

const navLegal: FooterNavProps = {
  title: 'Legal and compliance',
  items: [
    {
      title: 'Age restrictions: 18 years and above',
      link: '/rules/age-res'
    },
    {
      title: 'Gambling with a moderation',
      link: '/rules/terms'
    },
  ]
};

const KES_NAVS = [navSupport, navLegal];

const footerNavs = AppFeature.KES_PROJECT ? KES_NAVS : [navInfo, navCategory];

type PageFooterProps = {
  className?: string;
}

const b = bemCn('page-footer');

const PageFooter = (props: PageFooterProps) => {
  const { className } = props;
  const { t } = useTranslation();

  return (
    <footer className={b(null, className)} id="page-footer">
      <div className={b('wrapper')}>
        <div className={b('top-wrapper')}>
          <div className={b('logo-wrapper')}>
            <Logotype className={b('logo')} showFlag />
            <div className={b('support')}>
              <p className={b('support-text')}>
                {t('profile.nav.support', 'Служба поддержки')}
              </p>
              <ButtonIcon className={b('support-link')} href={SUPPORT_LINK} variant='secondary'
                icon={AppFeature.KES_PROJECT ? 'whatsapp' : 'support'}
                ariaLabel='Обратиться в техподдержку'
              />
            </div>

            <div className={b('download')}>
              <ButtonDownloadApp
                className={b('download-item')}
                type="android"
                link={ANDROID_APP}
              />
              {/* <ButtonDownloadApp className={b('download-item')} type="ios" /> */}
            </div>
          </div>

          <div className={b('nav-wrapper')}>
            {footerNavs.map((nav) => (
              <FooterNav className={b('nav')} key={nav.title}
                title={nav.title}
                items={nav.items.filter((item) => !item.disabled)}
              />
            ))}
          </div>
          <p className={b('copyright')}>
            &copy;&nbsp;2025&nbsp;{COMPANY_NAME} {t('copyright')}
          </p>
        </div>
        <div className={b('bottom-wrapper')}>
          {!AppFeature.KES_PROJECT && (
            <div className={b('companies')}>
              <ul className={b('companies-list')}>
                <li className={b('companies-item')}>
                  <a
                    className={b('companies-link')}
                    href={LOGO_BOOMAKER_RATINGS}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className={b('companies-img')} src={logoBookmakerRatings} alt="bookmaker-ratings.ru" />
                  </a>
                </li>
              </ul>
            </div>
          )}
          {!AppFeature.KES_PROJECT && (
            <LangSelector className={b('lang')} dropdown='top' />
          )}
          <SocialLinks className={b('social')} />
        </div>
      </div>
    </footer>
  );
};

export default PageFooter;
