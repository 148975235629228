import { bemCn } from '@shared/utils/helpers/bem-cn';
import { PropsWithCN } from '@shared/types/common';
import './loader-big.scss';


const b = bemCn('loader-big');

const LoaderBig = ({ className }: PropsWithCN) => (
  <div className={b(null, className)}>
    <img className={b('image')} src="/logo-dobro-animated-big.svg" alt="" />
  </div>
);

export default LoaderBig;
