export const privacyPolicy = {
  id: 'privacy-policy',
  title: 'Политика конфиденциальности',
  content: `
        <h1>СОГЛАСИЕ НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ “ДОБРО.БЕТ”</h1>
        <p>Заполняя форму, я выражаю свое свободное, однозначное и информированное согласие на обработку предоставленных мною персональных данных компании “ДОБРО.БЕТ” (далее – Организация или Оператор), на следующих условиях:</p>
        <ol>
          <li>Организация может осуществлять сбор, систематизацию, хранение, изменение, использование, обезличивание, блокирование, предоставление, удаление персональных данных. Организация вправе передавать контактные данные для организации рассылок, в том числе рассылок с других доменов.</li>
          <li>Способы обработки – с использованием средств автоматизации, без использования таких средств, а также смешанным образом.</li>
          <li>Цель обработки – направление уведомлений, рассылок информационного и рекламного характера, связанных с услугами Оператора.</li>
          <li>Объем обрабатываемых персональных данных: − имя, номер телефона, адрес электронной почты, номер игрового счета.</li>
          <li>Организация вправе предоставлять персональные данные, указанные в пункте 4, операторам сервисов обмена электронными сообщениями в целях направления уведомлений, рассылок информационного и рекламного характера, связанных с услугами Оператора.</li>
          <li>Срок, на который предоставляется согласие: 3 года с даты дачи согласия либо до момента отписки от получения рассылки.</li>
          <li>Права и обязанности субъекта персональных данных, механизм их реализации и последствия отказа в даче согласия указаны ниже.</li>
        </ol>

        <h2>Права субъектов персональных данных и механизм их реализации. Последствия отказа в даче согласия
        </h2>
        <table>
          <thead>
            <tr>
              <th>Право</th>
              <th>Содержание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Право на получение информации, касающейся обработки персональных данных (1)</td>
              <td>Вы имеете право на получение информации, касающейся обработки ваших персональных данных, содержащей: − наименование и местонахождение Оператора, − подтверждение факта обработки персональных данных Оператора, − ваши персональные данные и источник их получения, − правовые основания и цели обработки Ваших персональных данных, − срок, на который дано согласие на обработку персональных данных, − наименование и место нахождения уполномоченного лица, которому Оператора передает ваши персональные данные для обработки</td>
            </tr>
            <tr>
              <td>Право на получение информации о предоставлении персональных данных третьим лицам (2)</td>
              <td>Право на получение информации о предоставлении персональных данных третьим лицам (2)
                Вы вправе получать от Оператора информацию о предоставлении своих персональных данных уполномоченным лицам один раз в календарный год бесплатно
              </td>
            </tr>
            <tr>
              <td>Право на внесение изменений в свои персональные данные (3)</td>
              <td>Вы вправе требовать от Оператора внести изменения в Ваши персональные данные в случае, если они являются неполными, устаревшими или неточными</td>
            </tr>
            <tr>
              <td>Право требовать прекращения обработки персональных данных и (или) их удаления (4)</td>
              <td>Вы вправе требовать от Оператора бесплатного прекращения обработки своих персональных данных, включая их удаление, при отсутствии оснований для обработки персональных данных</td>
            </tr>
            <tr>
              <td>Право отозвать предоставленное ранее согласие на обработку персональных данных (5)</td>
              <td>Если Ваше согласие является законным основанием для обработки Ваших персональных данных, Вы можете в любое время отозвать его. Это не повлияет на законность осуществляемой обработки персональных данных на основании Вашего согласия до момента отзыва Вашего согласия</td>
            </tr>
            <tr>
              <td>Право на обжалование действий (бездействия) и решений оператора, связанных с обработкой персональных данных (6)</td>
              <td>Если Вы считаете, что наша обработка персональных данных нарушает применимое законодательство в сфере защиты персональных данных, вы можете подать жалобу в уполномоченный орган</td>
            </tr>
          </tbody>
        </table>

        <p>Обращаем Ваше внимание, что прекращение обработки Оператором Ваших персональных данных может сделать невозможным дальнейшее предоставление Вам услуг.</p>

        <p>Для реализации своих прав, связанных с обработкой персональных данных Организацией, субъект персональных данных подает в Организацию заявление в электронном виде на почту <a href="mailto:admin@dobrobk.com">admin@dobrobk.com</a></p>
        <p>Ваш запрос о реализации одного или нескольких из указанных выше прав должен содержать: ФИО; адрес; дату рождения; суть требований; идентификационный номер или номер документа, удостоверяющего личность (при отсутствии идентификационного номера), если эта информация указывалась при даче согласия или обработка персональных данных осуществляется без согласия субъекта, личную подпись или электронную подпись.</p>
        <p><b>ВАЖНО:</b> Организация не рассматривает заявления субъектов персональных данных, направленные иными способами (телефон, факс и т.п).</p>
        <p>За содействием в реализации прав субъект персональных данных может обратиться к лицу, ответственному за осуществление внутреннего контроля за обработкой персональных данных в Организации, направив сообщение на электронный адрес: <a href="mailto:admin@dobrobk.com">admin@dobrobk.com</a>.</p>

        <h2>Последствия отказа в даче согласия</h2>
        <p>Отказ в даче согласия сделает невозможным направление Вам рекламных и информационных рассылок.</p>
  `,
};
